import { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { FormActionTypes, FormSaveState } from '../context/form-reducer';
import { useFormContext } from './useFormContext';
import formSanitizer from '../utils/formSanitizer';

const useFormsHubClient = (formId: string, formType: string) => {
  const { dispatch } = useFormContext();

  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);

  const client = {
    initiateForm: () => {
      return connection!.send('InitiateForm');
    },
    createForm: () => {
      return connection!.send('createForm');
    },
    saveForm: (validate: boolean) => {
      dispatch({ type: FormActionTypes.SetSaveState, payload: FormSaveState.Saving });
      return connection!.send('SaveForm', validate);
    },
    fieldChange: (pageIndex: number, fieldName: string, value: any) => {
      return connection!.send('FieldChange', pageIndex, fieldName, value);
    },
    fileChange: (fieldName: string) => {
      return connection!.send('FileChange', fieldName);
    },
    validateForm: () => {
      return connection!.send('ValidateForm');
    },
    validatePage: (pageIndex: number) => {
      return connection!.send('ValidatePage', pageIndex);
    },
    resetForm: () => {
      return connection!.send('ResetForm');
    },
    submitForm: () => {
      return connection!.invoke('SubmitForm');
    },
    sendTimestampLogs: (logs: { eventType: string; timestamp: string }[] | null) => {
      return connection!.invoke('SendTimestampLogs', logs);
    },
  };

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(`/hubs/form/${formId}${!!formType ? '?formType=' + formType : ''}`)
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection !== null) {
      connection
        .start()
        .then(() => {
          connection!.on('ReceiveForm', (form) => {
            dispatch({ type: FormActionTypes.SetForm, payload: formSanitizer(form) });
            dispatch({ type: FormActionTypes.SetSaveState, payload: FormSaveState.Saved });
          });

          connection!.on('ReceiveValidation', (validation, validationType) => {
            dispatch({ type: FormActionTypes.SetFormValidation, payload: { validation: validation, validationType: validationType } });
          });

          connection!.on('ReceiveFormId', (formId) => {
            dispatch({ type: FormActionTypes.FormCreated, payload: formId });
          });
          client.initiateForm();
        })
        .catch((er) => console.error('Connection error', er));
    }

    return () => {
      connection?.stop();
    };
  }, [connection]);

  return client;
};

export { useFormsHubClient };
