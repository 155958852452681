import useSWR from "swr";

import { getTranslations } from "../apis/get-translations";
import { Labels } from "../models/statuspage/labels";
import { useContext } from "react";
import { AppContext } from "../context/app-context";

const EMPTY_TRANSLATIONS = {} as Labels;

export const useTranslations = () => {
  const { state } = useContext(AppContext);
  const { data, error } = useSWR(["api/translations", state.configuration.language], getTranslations);

  return {
    loading: !data && !error,
    error,
    translations: data ?? EMPTY_TRANSLATIONS,
  };
};
