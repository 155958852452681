import { PageLoader } from '@in/component-library';
import React from 'react';
import './InLoader.scss';

const InLoader = () => {
  return (
    <div className="page-loader">
      <PageLoader aria-label="Laster innhold" />
    </div>
  );
};

export default InLoader;
