import { Button, Notification, TextArea } from '@in/component-library';
import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { Message } from '../../models/statuspage/message';
import { useParams } from 'react-router-dom';
import { postMessageReply } from '../../apis/post-message-reply';
import { ApplicationCaseProcess } from '../../models/statuspage/application-case-process';
import { useTranslations } from '../../hooks/use-translations';
import { KeyedMutator } from 'swr';
import { Origin } from '../../models/statuspage/origin';

interface MessageReplyProps {
  message: Message;
  applicationCaseProcess: ApplicationCaseProcess | undefined;
  onPostMessage: KeyedMutator<ApplicationCaseProcess>;
}

const MessageReply: FC<MessageReplyProps> = ({ message, applicationCaseProcess, onPostMessage }) => {
  const [replyMessage, setReplyMessage] = useState('');
  const [caseClosed, setCaseClosed] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const { formId } = useParams<{ formId: string }>();
  const { translations } = useTranslations();
  const textAreaRef = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    const messagesFromAdvisor = applicationCaseProcess?.messages.filter((msg) => msg.origin === Origin.Advisor);

    const lastMessage = messagesFromAdvisor && messagesFromAdvisor[messagesFromAdvisor.length - 1];

    if (lastMessage) {
      const lastMessageDate = new Date(lastMessage.createdOn);
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      setCaseClosed(lastMessageDate < thirtyDaysAgo);
    }
  }, [applicationCaseProcess]);

  React.useEffect(() => {
    const warnOnPageExit = (e: BeforeUnloadEvent) => {
      if (replyMessage === '') return;

      e.preventDefault();
    };

    window.addEventListener('beforeunload', warnOnPageExit);

    return () => {
      window.removeEventListener('beforeunload', warnOnPageExit);
    };
  }, [replyMessage]);

  useEffect(() => {
    if (errorMessage) {
      textAreaRef.current?.focus();
    }
  }, [errorMessage]);

  const handleOnChangeMessageReply = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReplyMessage(e.target.value);

    if (e.target.value.length > 500) {
      setErrorMessage(translations.formStatusMessageReplyErrorMessage);
    } else {
      setErrorMessage('');
    }
  };

  const onMessageReplySubmit = async () => {
    setIsSaving(true);
    setMessageSent(false);
    if (replyMessage.length === 0) {
      setErrorMessage(translations.formStatusMessageReplyEmptyMessageError);
      setIsSaving(false);
      return;
    }
    if (formId) {
      const response = await postMessageReply(formId, message.id, replyMessage);
      if (response.code === 204) {
        setReplyMessage('');
        setErrorMessage('');
        onPostMessage();
        setMessageSent(true);
      }
    }
    setIsSaving(false);
  };

  return (
    <>
      <div className="message-reply">
        <Notification type="generic" className="message-reply__notification" fullWidth>
          {caseClosed ? (
            <p
              dangerouslySetInnerHTML={{
                __html: translations.formStatusMessageReplyNotificationCaseClosed,
              }}
            ></p>
          ) : (
            translations.formStatusMessageReplyNotificationCaseOpen
          )}
        </Notification>

        {!caseClosed && (
          <>
            <TextArea
              className="message-reply__textarea"
              label={translations.formStatusMessageReplyTextAreaTitle}
              description={translations.formStatusMessageReplyTextAreaDescription}
              maxCount={500}
              onChange={handleOnChangeMessageReply}
              value={replyMessage}
              autoResize
              errorMsg={errorMessage}
              disableResize
              ref={textAreaRef}
            />
            <div aria-live="polite" className="message-reply__action-container">
              <Button type="submit" onClick={onMessageReplySubmit} isLoading={isSaving} disabled={isSaving || errorMessage.length > 0}>
                {translations.formStatusMessageReplySendMessage}
              </Button>
            </div>
            {messageSent && (
              <div className="message-reply__status" aria-live="polite">
                {translations.formStatusMessageReplyStatus}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MessageReply;
