import React, { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../../context/app-context';

export const FormsInit: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useContext(AppContext);

  const getSearchParams = () => {
    if (state.configuration.featureFlags.redirectToFastForwardv3Enabled && state.user.currentUser.accountType === 'Person') {
      const search = searchParams.toString();
      if (search.includes('FastForward') && !search.includes('FastForwardv')) {
        return searchParams.toString().replace('FastForward', 'FastForwardv3');
      }
      if (search === '') {
        return 'formType=FastForwardv3';
      }
      if (!search.includes('formType')) {
        return searchParams.toString() + '&formType=FastForwardv3';
      }
    }
    return searchParams.toString();
  };
  useEffect(() => {
    navigate({
      pathname: '/form/0',
      search: getSearchParams(),
    });
  }, [searchParams, state.user.currentUser.accountType]);

  return <></>;
};
