import React, { useState } from 'react';
import { TextArea } from '@in/component-library';

export const TextAreaFieldWrapper = (props: any) => {

  const [internalValue, setInternalValue] = useState<any>(props.value);

  return <TextArea 
    { ...props }
    value={internalValue}
    onBlur={props.onChange}
    onChange={(e) => setInternalValue(e.currentTarget.value)}
  />
}
