import React, { FC } from 'react';

import { useTranslations } from '../../hooks/use-translations';
import { ApiResponse } from '../../apis/api-error';

import Loader from '../loader/loader';
import { Notification, Section } from '@in/component-library';

interface PageProps {
  inputs: any[];
  errors?: ApiResponse[];
  showMenu?: boolean;
  errorMessage?: string;
  skipToContent?: string;
  children?: React.ReactNode;
}

const Page: FC<PageProps> = ({ inputs, errors, errorMessage, children }) => {
  const { translations } = useTranslations();

  return (
    <>
      {errors && errors.some((error) => error !== undefined) ? (
        <Section>
          {errors.some((e) => e.code === 404) ? (
            <Notification fullWidth type="info">
              {translations.notFound}
            </Notification>
          ) : (
            <Notification fullWidth type="error">
              {errorMessage || translations.loadingFailed}
            </Notification>
          )}
        </Section>
      ) : inputs.some((input) => input === undefined) ? (
        <Section>
          <Loader />
        </Section>
      ) : (
        children
      )}
    </>
  );
};

export default Page;
