import { Language } from '../models';
import { MessageModel, NavItemDto, FeatureFlags } from '../models/greenplatform.models';
import { ActionMap } from '../types';
import { Locale, languages } from '../utils/locales';

export type ConfigurationState = {
  navItems: NavItemDto[];
  messages: MessageModel[];
  language: Language;
  locale?: Locale;
  featureFlags: FeatureFlags
};

export const initialConfigurationState: ConfigurationState = {
  navItems: [],
  messages: [],
  language: Language.nb,
  featureFlags: {
    featureAddToMyCourseEnabled: false, 
    featureMessageFeedbackEnabled: false,
    featureMessagesEnabled: false,
    featureMessageReplyEnabled: false,
    uxSignalsEnabled: false,
    useUxSignalsDemo: false,
    redirectToFastForwardv3Enabled: false
  },
};
export enum ConfigurationActionTypes {
  SetNavItems = 'SET_NAVITEMS',
  SetLanguage = 'SET_LANGUAGE',
  SetMessages = 'SET_MESSAGES',
  SetFeatureFlags = 'SET_FEATUREFLAGS'
}

// Configuration

type ConfigurationPayload = {
  [ConfigurationActionTypes.SetNavItems]: NavItemDto[];
  [ConfigurationActionTypes.SetLanguage]: Language;
  [ConfigurationActionTypes.SetMessages]: MessageModel[];
  [ConfigurationActionTypes.SetFeatureFlags]: FeatureFlags;
};

export type ConfigurationActions = ActionMap<ConfigurationPayload>[keyof ActionMap<ConfigurationPayload>];

export const configurationReducer = (state: ConfigurationState, action: ConfigurationActions) => {
  switch (action.type) {
    case ConfigurationActionTypes.SetNavItems:
      return {
        ...state,
        navItems: action.payload,
      };
    case ConfigurationActionTypes.SetLanguage:
      return {
        ...state,
        language: action.payload,
        locale: languages.get(action.payload),
      };
    case ConfigurationActionTypes.SetMessages:
      return {
        ...state,
        messages: action.payload
      }
    case ConfigurationActionTypes.SetFeatureFlags:
      return {
        ...state,
        featureFlags: action.payload
      }
    default:
      return state;
  }
};
