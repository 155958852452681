import React from 'react';

import { useTranslations } from '../../hooks/use-translations';
import { RecommendedCourseProps } from './recommended-course-props';
import { useAccount } from '../../hooks/use-account';

export const Recommendation: React.FC<RecommendedCourseProps> = ({ course, caseProcessData, headingLevel }) => {
  const { translations } = useTranslations();
  const { account } = useAccount();

  const splitRecommendationText = course.caseHandlerRecommendationRichText.split('<link>');

  const Header = headingLevel;
  return (
    <div className="case-handler">
      <div>
        <span className="case-handler__image-container">
          <img className="case-handler__image" alt={course.caseHandlerImageB?.imgAlt} src={course.caseHandlerImageB?.url} />
        </span>
      </div>

      <div className="case-handler__recommendation">
        <Header className="case-handler__title">
          {translations.formStatusGreeting}
          <span>{caseProcessData ? ` ${caseProcessData.submittedByPerson}` : account?.isPersonal && ` ${account.name}`}!</span>
        </Header>
        <p>
          {splitRecommendationText[0]?.substring(3)}
          <a target="_blank" rel="noreferrer" href={course.url}>
            {splitRecommendationText[1]}
          </a>
          {splitRecommendationText[2]?.substring(0, splitRecommendationText[2].length - 4)}
        </p>
      </div>
    </div>
  );
};
