import React, { useEffect, useState } from 'react';
import { FormPageWrapper } from './Pages/FormPageWrapper';
import { FormSummaryPageWrapper } from './Pages/FormSummaryPageWrapper';
import { Button, FaIcons, IconSize, MessageType, Notification, Select, Stepper, useMediaQuery } from '@in/component-library';
import './FormPage.scss';
import { useFormContext } from '../../hooks/useFormContext';
import { Confirmation, FormError } from '../../models/form.models';
import { Position } from '../Shared/Table/TableCard';
import { mapPageFields } from './fieldMapper';
import { useLocalizer } from '../../hooks/useLocalizer';
import { PushSiteImproveEvent } from '../../utils/siteimproveEvents';

interface PageMap<T> {
  [key: string]: T;
}

const PageMap: PageMap<React.FC<any>> = {
  formPage: FormPageWrapper,
  formSummaryPage: FormSummaryPageWrapper,
};

export const FormPage: React.FC<{
  page: any;
  submitButtonDisabled: boolean;
  goToPage: (steps: number) => void;
  validatePages: (pageIndex: number) => void;
  onConfirmationChange: (confirmations: Array<Confirmation>) => void;
  onFormSubmit: () => Promise<void>;
  onFieldChange: (event: any, field: any) => void;
  onFileUpload: (file: any, field: any) => Promise<any>;
  onFileDelete: (id: string, field: any) => Promise<any>;
  onFileClick: (file: any, pageIndex: number, field: any) => Promise<any>;
  dismissNotification: (e: FormError) => void;
}> = (props) => {
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  const mediaQueryMobile = useMediaQuery('(max-width: 48em)');
  const mobileMatch = mediaQueryMobile ? mediaQueryMobile.matches : undefined;
  const { formState } = useFormContext();
  const { l } = useLocalizer('Form');

  const previousLabel = formState.form.globalLabels.previousLabel;
  const nextLabel = formState.form.globalLabels.nextLabel;
  const submitLabel = formState.form.globalLabels.submitLabel;
  const formTitle = formState.form.title;
  const pageTitle = props.page.title;
  const filteredPages = formState.form.pages;
  const [currentPage, setCurrentPage] = useState<string>(filteredPages[formState.currentPage].subTitle);
  const PageComponent = PageMap[props.page.pageType];

  useEffect(() => {
    const focusableElements = document.querySelectorAll('.stepper-mobile ul li');

    if (focusableElements.length > 0) {
      focusableElements.forEach((element, index) => {
        (element as HTMLElement).tabIndex = index + 1;
      });
      const firstFocusableElement = focusableElements[formState.currentPage] as HTMLElement;
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  });

  useEffect(() => {
    if (mobileMatch !== undefined && mobileMatch !== isMobile) {
      setIsMobile(mobileMatch);
    }
  }, [mobileMatch, isMobile]);

  const goToPage = async (steps: number) => {
    const newPage = formState.currentPage + steps;
    if (steps < 0 && newPage < 0) return;
    if (steps > 0 && newPage === filteredPages.length) return;
    await props.goToPage(steps);
  };

  const goToField = async (steps: number, fieldid: string) => {
    await goToPage(steps);
    const fieldObjectList = window.document.getElementsByName(fieldid);
    if (fieldObjectList.length > 0) {
      fieldObjectList[0].focus();
      fieldObjectList[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const pagesLength = filteredPages.length;

  const tabsValid = () => {
    const mappedFields = formState.form.pages.map((p: any) => mapPageFields(p, formState, true));
    const validTabs = [];
    for (var i = 0; i < formState.currentPage; i++) {
      let pageIsTouched = mappedFields[i].some((field: any) => field.value !== null);
      let isValidPage = mappedFields[i].every((f: any) => f.validations.length === 0);

      validTabs.push(pageIsTouched ? isValidPage : undefined);
    }
    return validTabs;
  };

  useEffect(() => {
    document.title = `${formTitle} - ${pageTitle}`;
  }, [formTitle, pageTitle]);

  //textField for gathering information about previous 'innvilginger og skattefunn søknader'
  const hideEmptyNode = document.querySelectorAll('div.form-field');
  for (let i = 0; i < hideEmptyNode.length; i++) {
    hideEmptyNode[i].childNodes.length === 0 && hideEmptyNode[i].classList.add('hide-node');
  }

  const previousClicked = () => {
    PushSiteImproveEvent('NavigationButton', 'Previous');
    setCurrentPage(filteredPages[formState.currentPage - 1].subTitle);
    goToPage(-1);
  };

  const nextClicked = () => {
    PushSiteImproveEvent('NavigationButton', 'Next');
    setCurrentPage(filteredPages[formState.currentPage + 1].subTitle);
    goToPage(1);
  };

  const stepperClicked = (page: number) => {
    setCurrentPage(filteredPages[page].subTitle);
    PushSiteImproveEvent('stepper', (page + 1).toString());
    goToPage(page - formState.currentPage);
  };

  useEffect(() => {
    if (formState.currentPage !== 0) {
      props.validatePages(formState.currentPage);
    }
  }, []);

  return (
    <div>
      {!isMobile ? (
        <Stepper
          className="progressbar"
          stepCount={pagesLength}
          currentStep={formState.currentPage}
          valid={tabsValid()}
          stepperClick={(page: number) => stepperClicked(page)}
          stepErrorOnPageLabel={l('stepErrorOnPageLabel')}
          stepCompletedLabel={l('stepCompletedLabel')}
          stepLabel={l('stepLabel')}
          stepTitles={filteredPages.map((page: any) => page.subTitle)}
        />
      ) : (
        <div className="stepper-mobile">
          <div className="dropdown-label">
            <p>{l('stepperLabel')}</p>
            <p>{`${formState.currentPage + 1} ${l('stepperCount')} ${pagesLength}`}</p>
          </div>
          <Select
            className="dropdown"
            name="dropdown"
            label="dropdown"
            hideLabel={true}
            options={filteredPages.map((title: any, index: number) => ({ value: title.subTitle, text: `${index + 1}. ${title.subTitle}` }))}
            onChange={(title: string) => stepperClicked(filteredPages.map((title: any) => title.subTitle).indexOf(title))}
            value={currentPage}
          />
        </div>
      )}
      <div className="form-page">
        {PageComponent && (
          <div>
            <PageComponent {...props} goToField={goToField} />
          </div>
        )}
        {formState.errors.map((error: FormError, index: number) => (
          <>
            {error.errorMessage.length > 0 ? (
              <div className="notification">
                <div key={index} onClick={() => props.dismissNotification}>
                  <Notification children={error.errorMessage} type={MessageType.Error} dismissable fullWidth />
                </div>
              </div>
            ) : null}
          </>
        ))}

        <div className="page-action">
          {formState.currentPage !== 0 && (
            <Button
              className="page-action-button-left"
              transform={{ rotate: 270, size: 15, x: 3 }}
              onClick={previousClicked}
              theme="none"
              variant="outlined"
              iconName={FaIcons.ChevronUp}
              iconPosition={Position.Left}
              iconSize={IconSize.Small}
            >
              {previousLabel}
            </Button>
          )}
          {formState.currentPage < pagesLength - 1 && (
            <Button className="page-action-button-right" onClick={nextClicked}>
              {nextLabel}
            </Button>
          )}
          {formState.currentPage === pagesLength - 1 && (
            <Button className="page-action-button-right" isLoading={formState.submitClicked} disabled={props.submitButtonDisabled} onClick={props.onFormSubmit}>
              {submitLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
