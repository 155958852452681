import React from 'react';
import { ErrorMessage } from '@in/component-library';
import { InputSummaryField } from './SummaryFields/InputSummaryField';
import { RadioButtonSummaryField } from './SummaryFields/RadioButtonSummaryField';
import { InlineReadOnlyTextSummaryField } from './SummaryFields/InlineReadOnlyTextSummaryField';
import { GroupSummaryField } from './SummaryFields/GroupSummaryField';
import { FileUploadSummaryField } from './SummaryFields/FileUploadSummaryField';
import { CheckboxSummaryField } from './SummaryFields/CheckboxSummaryField';
import './FormSummaryField.scss';
import { useFormContext } from '../../hooks/useFormContext';
import cn from 'classnames'

interface SummaryFieldMap<T> {
  [key: string]: T;
}

const SummaryFieldMap: SummaryFieldMap<React.FC<any>> = {
  textField: InputSummaryField,
  textAreaField: InputSummaryField,
  numberField: InputSummaryField,
  selectField: InputSummaryField,
  radioButtonField: RadioButtonSummaryField,
  groupField: GroupSummaryField,
  inlineReadOnlyTextField: InlineReadOnlyTextSummaryField,
  fileUploadField: FileUploadSummaryField,
  checkboxField: CheckboxSummaryField,
};

export const FormSummaryField: React.FC<{
  field: any, 
  onValidationClick: () => void,
  onFileClick: (file: any) => Promise<any>,
  goToField: () => void,
  pageNumber: number
}> = (props) => {
  const { formState } = useFormContext();

  const field = formState.form.fields.find((f: any) => f.key === props.field.key);

  const validation = formState.validationByFieldName[props.field.name] ?? null;
  const errorMessage = validation ? validation.join(', ') : null;

  const FieldComponent = !field.hidden && SummaryFieldMap[field.fieldType];

  return field.hidden
  ? (<></>) 
  : (
    <div className="formSummaryField">
      <div className={cn('formSummaryField-card', {"fieldError": errorMessage})}>
        {FieldComponent && (
          <FieldComponent
            class={cn('fieldComponentClass', { [field.fieldType]: field.fieldType })}
            {...field}
            onFileClick={props.onFileClick}
            goToField={formState.form?.submitted ? null : props.goToField}
            pageNumber={props.pageNumber}
          />
        )}
        {!FieldComponent && !field.hidden && (
          <>
            {field.label} - <em>{field.fieldType}</em>: {field.value}
          </>
        )}
      </div>
      {errorMessage &&
        <ErrorMessage errorMessage={errorMessage} />
      }
    </div>
  );
};
