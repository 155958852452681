import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Button, Tabs, Card, FaIcons, Link } from '@in/component-library';
import './FormVMAPartners.scss';
import { useLocalizer } from '../../hooks';
import { AppContext } from '../../context/app-context';

interface Partner {
  name: string;
  municipality: string | null;
  county: string;
  url: string;
}

interface PartnersTranslations {
  partners: Partner[];
  title: string;
  description: string;
}

interface Tab {
  ['aria-label']: string;
  ['aria-selected']: boolean;
  ['aria-controls']: string;
}

/**
 * Props for the `FormVMAPartners` component.
 *
 * @typedef {Object} FormVMAPartnersProps
 *
 * @property {() => void} backToForm - A function which is called when clicking the back button. If not provided, the back button will not be rendered.
 */
export interface FormVMAPartnersProps {
  backToForm?: () => void;
}

/**
 * This component displays the VMA partners as a list of cards.
 *
 * @param {FormVMAPartnersProps} props - The props for this component.
 *
 * @returns {JSX.Element} The rendered list of card components
 */
export const FormVMAPartners: React.FC<FormVMAPartnersProps> = (props) => {
  const { l } = useLocalizer('VMAPartners');
  const [activeTab, setActiveTab] = useState(0);
  const { state } = useContext(AppContext);
  const [partnersTranslations, setPartnersTranslations] = useState<PartnersTranslations | null>(null);

  useEffect(() => {
    const fetchPartners = async () => {
      const response = await fetch(`/api/v1/partners/${state.configuration.language}`);
      const data = await response.json();
      setPartnersTranslations(data.partners);
    };
    fetchPartners();
  }, [state.configuration.language]);

  const counties = useMemo(() => {
    return partnersTranslations ? Array.from(new Set(partnersTranslations.partners.map((partner) => partner.county))) : [];
  }, [partnersTranslations?.partners]);

  const partners: Partner[][] = useMemo(() => {
    if (!partnersTranslations || !partnersTranslations.partners) {
      return [[]];
    }

    const sortByName = (a: Partner, b: Partner) => a.name.localeCompare(b.name, 'nb');
    const allPartners = partnersTranslations.partners.sort(sortByName);
    const partnersCatgorizedByCounty = counties.map((county) => partnersTranslations.partners.filter((partner) => partner.county === county).sort(sortByName));

    return [allPartners, ...partnersCatgorizedByCounty];
  }, [partnersTranslations?.partners]);

  const backToFormHandler = useCallback(() => {
    props.backToForm && props.backToForm();
  }, [props.backToForm]);

  const tabTitles = [l('tabsTitleAll')].concat(counties);
  const tabs: Tab[] = tabTitles.map((title, i) => {
    return {
      ['aria-label']: `${title} (${partners[i].length})`,
      ['aria-selected']: i == activeTab,
      ['aria-controls']: 'panel-' + (i + 1),
    };
  });

  const tabpanels = partners.map((partners, i) => {
    return (
      <div key={i} id={'panel-' + (i + 1)} role="tabpanel" hidden={activeTab !== i}>
        <ol className="VMA-partners__card-container">
          {partners.map((partner, i) => (
            <li aria-posinset={i + 1} aria-setsize={partners.length}>
              <Card className="VMA-partners__card" key={i} target="_blank" backgroundColor={'white'} boxShadow="elevated">
                <h3 className="VMA-partners__card-title">{partner.name}</h3>
                <div>
                  <p className="VMA-partners__card-text">{partner.municipality}</p>
                  {partner.url ? (
                    <div className="VMA-partners__card-link-container">
                      <Link iconName={FaIcons.GlobeLight} href={partner.url} iconPosition="left" rel="noopner" target="_blank">
                        {l('websiteLinkLabel')}
                      </Link>
                    </div>
                  ) : null}
                </div>
              </Card>
            </li>
          ))}
        </ol>
      </div>
    );
  });

  return (
    <>
      {partnersTranslations?.partners ? (
        <div className="VMA-partners">
          <div className="VMA-partners__grid">
            <div className="VMA-partners__textblock">
              <h1>{partnersTranslations?.title}</h1>
              <p>{partnersTranslations?.description}</p>
            </div>
            <div className="VMA-partners__tabs-container">
              {tabs && (
                <>
                  <Tabs className="VMA-partners__tabs" tabs={tabs} setActiveTab={(focusPosition) => setActiveTab(focusPosition)} />
                  <h2 className="VMA-partners__tabpanel-title">{partners[activeTab].length + ' ' + l('tabpanelTitle')}</h2>
                  {tabpanels}
                </>
              )}
              <div>
                {props.backToForm && (
                  <Button className="VMA-partners__button" onClick={backToFormHandler}>
                    {l('backButtonLabel')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
