import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { useDateUtils, useNumberUtils, useResponsiveView } from '../../../hooks';
import { DateTime } from 'luxon';
import { IconSize } from '@in/component-library';
import './TableCard.scss';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

interface TableCardProps {
  footNote?: string;
  mappingDefinition: MappingDefinition;
  model: any[] | undefined;
  lightVersion?: boolean;
  viewModes?: ElementViewMode[];
  opaque?: boolean;
  noDataMessage?: string;
  children: ReactNode;
}

export enum ElementViewMode {
  Default,
  Warning,
  Warning2,
}

export interface MappingDefinition {
  columns: Column[];
  mobileView?: MobileView;
  title?: string;
}

export interface ElementType {
  type: ElementViewMode;
  colorSet: ColorSet;
}

export interface ColorSet {
  elementBackground: string;
  headerBackground: string;
  headerText: string;
  leftMargin: string;
  text: string;
}

interface Column {
  header: string;
  headerAlternativeKey?: string;
  key: string;
  position: Position;
  type: ColumnType;
  weight: ColumnWeight;
  width?: number;
  headerTitle?: string;
}

export enum ColumnType {
  CustomerIdentification,
  Date,
  DateShort,
  DateYear,
  DecimalNumber,
  Normal,
  OrganizationNumber,
}

export enum ColumnWeight {
  Bold,
  Normal,
}

export enum Position {
  Left,
  Right,
}

export enum ColorTheme {
  Gray,
  Warning,
}

export enum ColumnHeaderType {
  Value,
  LabelValue,
}

interface MobileView {
  breakpoint: number;
  columnHeaderKey: string;
  columnHeaderType?: ColumnHeaderType;
  dividers?: number[];
  elementTypes?: ElementType[];
}

interface InternalTable {
  rows: InternalRow[];
}

interface InternalRow {
  cells: InternalCell[];
  delete?: boolean;
  header?: boolean;
  underline?: boolean;
  colorSet?: ColorSet;
}

interface InternalCell extends Column {
  cellType: CellType;
  colSpan: number;
  value: any;
  colorTheme: ColorTheme;
}

export enum InternalCommand {
  DeleteRow = 'DeleteRow',
}

enum CellType {
  DataHeader,
  TableHeader,
  Normal,
  Padding,
}

export interface TableRowProps {
  lightVersion?: boolean;
  row: InternalRow;
  rowIndex: number;
}

function getCellCustomColor(cellIndex: number, row: InternalRow, cell: InternalCell) {
  let customHeaderBackgroundColor, customBackgroundColor, customTextColor, customHeaderTextColor, customLeftMargin;
  if (cell.colorTheme === cell?.colorTheme) {
    customHeaderBackgroundColor = cell.colorTheme === cell?.colorTheme && row.colorSet?.headerBackground ? row.colorSet?.headerBackground : undefined;
    customBackgroundColor = cell.colorTheme === cell?.colorTheme && row.colorSet?.elementBackground ? row.colorSet?.elementBackground : undefined;
    customTextColor = cell.colorTheme === cell?.colorTheme && row.colorSet?.text ? row.colorSet?.text : undefined;
    customHeaderTextColor = cell.colorTheme === cell?.colorTheme && row.colorSet?.headerText ? row.colorSet?.headerText : undefined;
    const customLeftMarginColor = cell.colorTheme === cell?.colorTheme && cellIndex === 0 && row.colorSet?.leftMargin ? row.colorSet?.leftMargin : undefined;
    customLeftMargin = customLeftMarginColor !== undefined ? '3px solid ' + customLeftMarginColor : undefined;
    return [customHeaderBackgroundColor, customBackgroundColor, customTextColor, customHeaderTextColor, customLeftMargin];
  }
  return [];
}

function getCellWidth(cellWidth:number | undefined) {
  if (cellWidth !== undefined) {
    return cellWidth + 'px';
  }
  return undefined;
}

export const TableRow: FC<TableRowProps> = (props) => {
  const { toFormattedCoreViewDate, toCoreViewYear } = useDateUtils();
  const { toFixedDisplayValue, withThousandSeparators } = useNumberUtils();

  return (
    <tr key={props.rowIndex} className={cn({ 'column-header': props.row.header === true }, { 'column-header-light': props.lightVersion === true })}>
      {props.row.cells.map((cell, cellIndex) => {
        let displayValue = '-';
        if (cell.value !== undefined) {
          switch (cell.type) {
            case ColumnType.CustomerIdentification:
              if (!isNaN(+cell.value) && cell.value.length > 1) {
                let fragments: RegExpMatchArray | null = cell.value.match(/(.{4}|.{3}|.{2}|.{1})/g);
                displayValue = fragments !== null ? fragments.join(' ') : cell.value;
                break;
              }
              displayValue = cell.value;
              break;
            case ColumnType.Date:
            case ColumnType.DateShort:
            case ColumnType.DateYear:
              displayValue =
                cell.type === ColumnType.DateShort
                  ? toFormattedCoreViewDate(cell.value, DateTime.DATE_SHORT)
                  : cell.type === ColumnType.Date
                  ? toFormattedCoreViewDate(cell.value)
                  : cell.type === ColumnType.DateYear
                  ? toCoreViewYear(cell.value)
                  : 'n/a';
              break;
            case ColumnType.DecimalNumber:
              displayValue = toFixedDisplayValue(withThousandSeparators(cell.value), 2) + '';
              break;
            case ColumnType.OrganizationNumber:
              if (!isNaN(+cell.value) && cell.value.length === 9) {
                let fragments: RegExpMatchArray | null = cell.value.match(/(.{3})/g);
                displayValue = fragments !== null ? fragments.join(' ') : cell.value;
                break;
              }
              displayValue = cell.value;
              break;
            default:
              displayValue = cell.value;
              break;
          }
        }

        // default class
        let className = cn(
          'item data-hj-suppress',
          { bold: cell.weight === ColumnWeight.Bold },
          { first: cellIndex === 0 },
          { last: cellIndex === props.row.cells.length - 1 },
          { right: cell.position === Position.Right },
          { rowPadding: cell.cellType === CellType.Padding },
          { underline: cell.cellType !== CellType.Padding && props.row.underline === true },
          { warning: props.row.header === true && cell.colorTheme === ColorTheme.Warning },
          { 'warning-50': props.row.header !== true && cell.colorTheme === ColorTheme.Warning },
        );

        // Custom warning colors
        const [customHeaderBackgroundColor, customBackgroundColor, customTextColor, customHeaderTextColor, customLeftMargin] 
              = getCellCustomColor(cellIndex, props.row, cell);

        return props.row.header === true ? (
          cell.cellType === CellType.Padding ? (
            <td key={cellIndex - 100} className={className} style={{ minWidth: getCellWidth(cell.width), maxWidth: getCellWidth(cell.width), backgroundColor: customHeaderBackgroundColor, borderLeft: customLeftMargin }}></td>
          ) : (
            <th key={cellIndex} title={cell.headerTitle} colSpan={cell.colSpan} className={className} style={{ minWidth: getCellWidth(cell.width), maxWidth: getCellWidth(cell.width), backgroundColor: customHeaderBackgroundColor, color: customHeaderTextColor }}>
              {cell.cellType === CellType.DataHeader ? cell.header : displayValue}
            </th>
          )
        ) : cell.cellType === CellType.Padding ? (
          <td key={cellIndex - 100} className={className} style={{ minWidth: getCellWidth(cell.width), maxWidth: getCellWidth(cell.width), backgroundColor: customBackgroundColor, borderLeft: customLeftMargin }}></td>
        ) : (
          <td key={cellIndex} colSpan={cell.colSpan} className={className} style={{ minWidth: getCellWidth(cell.width), maxWidth: getCellWidth(cell.width), backgroundColor: customBackgroundColor, color: customTextColor }}>
            {cell.cellType === CellType.DataHeader ? cell.header : displayValue}
          </td>
        );
      })}
    </tr>
  );
};

function wrapWithPadding(definition: Column, row: InternalRow, colorTheme: ColorTheme) {
  let cell = { ...definition, colSpan: 1, width: undefined, cellType: CellType.Padding, value: 0, colorTheme: colorTheme };
  row.cells.unshift(cell);
  row.cells.push(cell);
  return row;
}

export const TableCard: FC<TableCardProps> = (props) => {
  const { width } = useResponsiveView();

  if (props.model === undefined || props.model.length === undefined) {
    return (
      <LoadingSpinner iconSize={IconSize.Small} />
    );
  }
  if (props.model.length === 0) {
    return <div className="item error first">{props.noDataMessage}</div>;
  }

  let table: InternalTable = { rows: [] } as InternalTable;

  const mobileView = props.mappingDefinition.mobileView !== undefined && width < props.mappingDefinition.mobileView?.breakpoint;

  interface ColorSetHash {
    [key: string]: ColorSet;
  }
  const elementTypesHash: ColorSetHash = {};
  props.mappingDefinition.mobileView?.elementTypes?.forEach((element) => {
    elementTypesHash[element.type] = element.colorSet;
  });

  function getViewModeByIndex(viewModes: ElementViewMode[] | undefined, elementIndex: number): number {
    if (viewModes !== undefined) {
      return viewModes[elementIndex] as ElementViewMode;
    }
    return ElementViewMode.Default;
  }

  if (!mobileView) {
    let row = { cells: [], header: true } as InternalRow;
    props.mappingDefinition.columns.forEach((definition: any) => {
      let cell = { ...definition, colSpan: 1, cellType: CellType.DataHeader } as InternalCell;
      row.cells.push(cell);
    });
    table.rows.push(row);

    props.model.forEach((object) => {
      const underline = props.lightVersion === true ? false : true;
      let row = { cells: [], underline: underline } as InternalRow;
      props.mappingDefinition.columns.forEach((definition) => {
        let cell = { ...definition, colSpan: 1, cellType: CellType.Normal, value: object[definition.key] } as InternalCell;
        if (cell.value === InternalCommand.DeleteRow) {
          row.delete = true;
        }
        row.cells.push(cell);
      });
      if (row.delete !== true) {
        table.rows.push(row);
      }
    });
  } else {
    let headerKey = props.mappingDefinition.mobileView?.columnHeaderKey;
    props.model.forEach((object, elementIndex) => {
      const colorTheme = getViewModeByIndex(props.viewModes, elementIndex);
      props.mappingDefinition.columns.forEach((definition, definitionIndex) => {
        if (definition.key === headerKey) {
          let row = { cells: [], header: true, colorSet: elementTypesHash[colorTheme] } as InternalRow;
          if (props.mappingDefinition.mobileView?.columnHeaderType === ColumnHeaderType.LabelValue) {
            const cell1 = { ...definition, colSpan: 1, width: undefined, cellType: CellType.DataHeader, value: definition.header, colorTheme: colorTheme } as InternalCell;
            row.cells.push(cell1);
            const cell2 = {
              ...definition,
              colSpan: 1,
              width: undefined,
              position: Position.Right,
              cellType: CellType.TableHeader,
              value: object[definition.key],
              colorTheme: colorTheme,
            } as InternalCell;
            row.cells.push(cell2);
          } else {
            const cell = { ...definition, colSpan: 2, width: undefined, cellType: CellType.TableHeader, value: object[definition.key], colorTheme: colorTheme } as InternalCell;
            row.cells.push(cell);
          }
          row = wrapWithPadding(definition, row, colorTheme);
          table.rows.push(row);
        } else {
          let row = {
            cells: [],
            underline: props.mappingDefinition.mobileView?.dividers !== undefined && props.mappingDefinition.mobileView.dividers.indexOf(definitionIndex) > -1,
            colorSet: elementTypesHash[colorTheme],
          } as InternalRow;
          const cell1 = {
            ...definition,
            colSpan: 1,
            width: undefined,
            cellType: CellType.DataHeader,
            position: Position.Left,
            value: definition.header,
            colorTheme: colorTheme,
          } as InternalCell;
          row.cells.push(cell1);
          const cell2 = { ...definition, colSpan: 1, width: undefined, cellType: CellType.Normal, value: object[definition.key], colorTheme: colorTheme } as InternalCell;
          row.cells.push(cell2);
          if (cell2.value !== InternalCommand.DeleteRow) {
            table.rows.push(row);
          }
          row = wrapWithPadding(definition, row, colorTheme);
        }
      });
    });
  }

  return (
    <div className={cn('tableCard-wrapper', { light: props.lightVersion },{ opaque: props.opaque })}>
      <table className={cn('tableCard')}>
        <thead>
          <TableRow key={-1} rowIndex={-1} row={table.rows[0]} lightVersion={props.lightVersion}></TableRow>
        </thead>
        <tbody>
          {table.rows.length > 1 &&
            table.rows.slice(1).map((row, rowIndex) => {
              return <TableRow key={rowIndex} rowIndex={rowIndex} row={row}></TableRow>;
            })}
        </tbody>
        {props.footNote !== undefined
          ? table.rows.length > 1 && (
              <tfoot>
                <tr key={-2}>
                  <td className="item first" colSpan={mobileView === true ? 2 : table.rows[0].cells.length}>
                    {props.footNote}
                  </td>
                </tr>
              </tfoot>
            )
          : undefined}
      </table>
      {props.children}
    </div>
  );
};
