import React, { FC, SyntheticEvent, useContext } from 'react';
import useSWR from 'swr';

import { useTranslations } from '../../hooks/use-translations';
import { getStatusPage } from '../../apis/get-status-page';

import { Button, ButtonVariant, Spinner, Theme, Tabs } from '@in/component-library';
import { useNavigate, useParams } from 'react-router-dom';
import { del } from '../../apis/utils';
import { ApplicationStatus } from '../../models/statuspage/application-status';
import ApplicationCaseinformation from '../../components/application-case-information/application-case-information';
import ConfirmationModal from '../../components/confirmation-modal/confirmation-modal';

import CaseProcess from '../../components/case-process/case-process';
import { getCaseProcess } from '../../apis/get-case-process';
import { Recommendation } from '../../models/statuspage/recommendation';
import Loader from '../../components/loader/loader';
import { Message } from '../../models/statuspage/message';
import { postSeenByRecipient } from '../../apis/seen-by-recipient';
import UXSignalsWidget from '../../components/UX-signals-widget/UX-signals-widget';
import Communication from '../../components/messages/communication';
import { AppContext } from '../../context/app-context';
import { Language } from '../../models';
import Page from '../../components/page/page';
import './status-page.scss';

const StatusPage: FC = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { translations } = useTranslations();
  const { formId, index } = useParams<{ formId: string; index: string }>();

  const { data, error } = useSWR(`${formId}`, getStatusPage, {
    refreshInterval: 5 * 60 * 1000,
  });
  const { data: caseProcess, mutate } = useSWR([formId], getCaseProcess, {
    refreshInterval: 15 * 1000,
  });

  const [unseenMessages, setUnseenMessages] = React.useState<Message[]>();

  const [recommendedCourse, setRecommendedCourse] = React.useState<Recommendation[] | undefined>();
  const [selected, setSelected] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [deleteApplication, setDeleteApplication] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<number>(0);

  React.useEffect(() => {
    setRecommendedCourse(caseProcess?.recommendations);
    if (caseProcess && caseProcess.messages) {
      const unseenMessages = caseProcess.messages.filter((message) => !message.seenByRecipient);
      setUnseenMessages(unseenMessages);
    }
  }, [caseProcess]);

  React.useEffect(() => {
    if (data?.application.title && translations.applicationTitle && translations.companyName) {
      document.title = `${data?.application.title} - ${translations.applicationTitle} - ${translations.companyName}`;
    }
  }, [translations, data]);

  React.useEffect(() => {
    const newActiveTab = Number(index);
    if (newActiveTab === 0 || newActiveTab === 1) {
      setActiveTab(newActiveTab);
    } else {
      setActiveTab(0);
    }
  }, [index]);

  React.useEffect(() => {
    if (activeTab === 1 && unseenMessages && formId) {
      unseenMessages.forEach((message) => {
        handleSeenByRecipient(message);
      });
    }
  }, [activeTab, unseenMessages, formId]);

  const buildTabLink = (text: string, index: number) => ({
    title: text,
    onClick: () => {
      setActiveTab(index);
      navigate(`/form/${formId}/status/${index}`);
    },
    'aria-selected': index === activeTab,
    'aria-label': text,
  });

  const handleSeenByRecipient = async (msg: Message) => {
    return await postSeenByRecipient(formId, msg.id);
  };

  const deleteDraftedApplication = async () => {
    setDeleteApplication(true);
    const response = await del(data?.application.deleteUrl ? data.application.deleteUrl : '');
    response.code === 200 ? navigate(`/applications/`) : setHasError(true);
    setDeleteApplication(false);

    return true;
  };

  const handleDeleteApplication = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSelected(true);
  };

  // Code to set user attributes for hotjar. Used to filter which users get survey.
  if (caseProcess && caseProcess.recommendations) {
    var hotjarAttributes = Object.assign(
      {},
      ...caseProcess.recommendations.map((r: Recommendation) => ({
        [r.identifier]: caseProcess.recommendations.length > 0 ? true : false,
      })),
      { recommendation: caseProcess.recommendations.length > 0 ? true : false }
    );
    if ((window as any).hj) (window as any).hj('identify', null, hotjarAttributes);
  }

  return (
    <Page inputs={[data]} errors={[error]} skipToContent={`form/${data?.application.id}/status#maincontent`}>
      <div className="form-status" tabIndex={-1}>
        <h1 className="form-status__title data-hj-suppress">{data?.application.title}</h1>

        <Tabs
          className="form-status__tablist"
          tabs={[buildTabLink(translations.formStatusTabStatus, 0), buildTabLink(translations.formStatusTabCommunication, 1)]}
          setActiveTab={(focusPosition) => setActiveTab(focusPosition)}
        />

        <div className="form-status__case-process">
          {activeTab === 0 && (
            <div tabIndex={0}>
              <CaseProcess caseProcessData={caseProcess} applicationData={data} recommendations={recommendedCourse} />
              {state.configuration.language !== Language.en && <UXSignalsWidget isReadyToLoadScript={data !== undefined} id="panel-ilxhy6l9tn" />}
            </div>
          )}

          {activeTab === 1 && (
            <div className="form-status__communication" tabIndex={0}>
              <div aria-live="polite">
                {caseProcess?.messages && caseProcess.messages.length > 0 ? (
                  <Communication applicationCaseProcess={caseProcess} formid={formId} onPostMessage={mutate} />
                ) : caseProcess?.messages === undefined ? (
                  <Loader />
                ) : (
                  <p>{translations.formStatusTabCommunicationEmpty}</p>
                )}
              </div>
            </div>
          )}

          <div className="form-status__case">
            <ApplicationCaseinformation
              submittedByPerson={caseProcess?.submittedByPerson}
              projectUrl={data?.application.projectUrl}
              caseHandledBy={caseProcess?.caseHandledBy}
            />
            {data?.application.statusType === ApplicationStatus.Draft && (
              <Button variant={ButtonVariant.Outlined} theme={Theme.Danger} onClick={handleDeleteApplication}>
                {translations.formApplicationDelete}
              </Button>
            )}
          </div>
        </div>
      </div>

      <ConfirmationModal onClick={() => deleteDraftedApplication()} visible={selected} onClose={() => setSelected(false)} errorMessage={hasError} />
      {deleteApplication && <Spinner className="application-card__spinner" />}
    </Page>
  );
};

export default StatusPage;
