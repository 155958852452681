import { Checkbox } from '@in/component-library';
import React, { useEffect, useState } from 'react';
import { useLocalizer } from '../../../hooks';
import { useFormContext } from '../../../hooks/useFormContext';
import { Confirmation } from '../../../models/form.models';
import { FormField } from '../FormField';
import { FormSummary } from '../FormSummary';
import { FormSummaryValidation } from '../FormSummaryValidation';
import './FormSummaryPageWrapper.scss'

export const FormSummaryPageWrapper: React.FC<{
  page: any;
  goToField: (steps: number, fieldid: string) => void;
  onFieldChange: (event: any, field: any) => void;
  onFileUpload: (file: any, field: any) => Promise<any>;
  onFileDelete: (id: string, field: any) => Promise<any>;
  onFileClick: (file: any, pageIndex: number, field: any) => Promise<any>;
  onConfirmationChange: (confirmations: Array<Confirmation>) => void
}> = (props) => {

  const [localConfirmations, setLocalConfirmations] = useState<Confirmation[]>([]);
  const { formState } = useFormContext();

  const { l } = useLocalizer('Form');
  
  const confirmationChange = (event: any) => {
    const confirmations = localConfirmations;
    const index = confirmations.findIndex(c => c.text === event.currentTarget.value);
    confirmations[index].isConfirmed = !confirmations[index].isConfirmed;

    setLocalConfirmations([...confirmations]);
    props.onConfirmationChange(confirmations);
  }

  useEffect(() => {
    if(!!props.page.confirmations){
      setLocalConfirmations([...props.page.confirmations.map((c: Confirmation) => {
        return { ...c, isConfirmed: false }
      })]);
    }
  }, []);

  const confirmationError = formState.submitClicked && localConfirmations.filter(c => c.isConfirmed === false).length > 0
    ? l('noConfirmationChosenMessage')
    : undefined;

  return (
    <>
      <h2 className="h2AdjustedForTopBorder">{props.page.title}</h2>
      <FormSummaryValidation goToField={props.goToField} />
      {props.page.description && <div dangerouslySetInnerHTML={{ __html: props.page.description }} />}

      <FormSummary goToField={props.goToField} onFileClick={props.onFileClick} />
      
      
      
      {props.page.fields?.map((field: any, i: number) => (
        <FormField
          field={field}
          key={i}
          onChange={(event: any) => props.onFieldChange(event, field)}
          onFileUpload={(file: any) => props.onFileUpload(file, field)}
          onFileDelete={(id: string) => props.onFileDelete(id, field)}
        />
      ))}

      {props.page.confirmations &&
        <>
          <Checkbox
            label={props.page.confirmationLabel ?? undefined}
            name='confirmations'
            errorMsg={confirmationError}
            options={props.page.confirmations.map((c: any) => {
              return {
                value: c.text,
                text: c.text,
                selected: c.isConfirmed
              }
            })}
            onChange={confirmationChange}
          />
        </>
      }
    </>
  );
};
