import { Confirmation, FormError, FormValidationType } from '../models/form.models';
import { ActionMap } from '../types';

export type FormState = {
  form?: any;
  currentPage: number;
  validationByFieldName: {
    [Key: string]: Array<string>
  };
  formCreated: boolean;
  urlUpdated: boolean;
  formValidated: boolean;
  confirmations: Confirmation[]
  focusField: string,
  completedPages: Array<Number>,
  summaryShown: boolean,
  submitClicked: boolean,
  formSubmitted: boolean,
  errors: FormError[],
  saveState: FormSaveState,
  formId: string,
  lastEditedField: Field | undefined
  formStarted: boolean;
};

type Field = {
  name: string;
  value: string;
  pageIndex: number;
}

export const initialFormState: FormState = {
  currentPage: 0,
  formCreated: false,
  urlUpdated: false,
  validationByFieldName: {},
  formValidated: false,
  confirmations: [],
  focusField: '',
  completedPages: [],
  summaryShown: false,
  submitClicked: false,
  formSubmitted: false,
  errors: [],
  saveState: 0,
  formId: "",
  lastEditedField: undefined,
  formStarted: false,
};

export enum FormActionTypes {
  ResetState = 'RESET_STATE',
  SetForm = 'SET_FORM',
  SetCurrentPage = 'SET_CURRENT_PAGE',
  UrlUpdated = 'URL_UPDATED',
  FormCreated = 'FORM_CREATED',
  FormPageFieldChange = 'FORMPAGE_FIELD_CHANGE',
  SetFormValidation = 'SET_FORM_VALIDATION',
  FocusField = 'FOCUS_FIELD',
  CompletePage = 'COMPLETE_PAGE',
  SummaryShown = 'SUMMARY_SHOWN',
  SubmitClicked = 'SUBMIT_CLICKED',
  FormSubmitted = 'FORM_SUBMITTED',
  SetConfirmations = 'SET_CONFIRMATIONS',
  SetError = 'SET_ERROR',
  RemoveError = 'REMOVE_ERROR',
  SetSaveState = 'SET_SAVE_STATE',
  SetFormId = 'SET_FORM_ID',
  FormStarted = 'FORM_STARTED'
}

export type FormPageFieldChangePayload = {
  pageIndex: number,
  field: any
  value: any,
}

export type FormValidatedPayload = {
  validation: {
    [Key: string]: Array<string>
  },
  validationType: FormValidationType
}

export enum FormSaveState {
  Saved,
  Saving,
  Failed
}

// Form

type FormPayload = {
  [FormActionTypes.ResetState]: null,
  [FormActionTypes.SetForm]: {};
  [FormActionTypes.SetCurrentPage]: number;
  [FormActionTypes.UrlUpdated]: null;
  [FormActionTypes.FormCreated]: string;
  [FormActionTypes.FormPageFieldChange]: FormPageFieldChangePayload;
  [FormActionTypes.SetFormValidation]: FormValidatedPayload;
  [FormActionTypes.FocusField]: string;
  [FormActionTypes.CompletePage]: number;
  [FormActionTypes.SummaryShown]: boolean;
  [FormActionTypes.SubmitClicked]: boolean;
  [FormActionTypes.FormSubmitted]: boolean;
  [FormActionTypes.SetConfirmations]: Array<Confirmation>;
  [FormActionTypes.SetError]: string;
  [FormActionTypes.RemoveError]: number;
  [FormActionTypes.SetSaveState]: FormSaveState;
  [FormActionTypes.SetFormId]: string;
  [FormActionTypes.FormStarted]: boolean;
};

export type FormActions = ActionMap<FormPayload>[keyof ActionMap<FormPayload>];

export const formReducer = (state: FormState, action: FormActions) => {
  switch (action.type) {
    case FormActionTypes.ResetState:
      return { ...initialFormState };
    case FormActionTypes.SetForm:
      return {
        ...state,
        form: action.payload,
      };
    case FormActionTypes.SetCurrentPage:
      const newValue = state.currentPage + action.payload;
      return {
        ...state,
        formValidated: false,
        focusField: '',
        confirmations: [],
        submitClicked: false,
        currentPage: newValue,
      };
    case FormActionTypes.FormCreated:
      return {
        ...state,
        formCreated: true,
        formId: action.payload
      }
    case FormActionTypes.UrlUpdated:
      return {
        ...state,
        urlUpdated: true,
      }
    case FormActionTypes.FormPageFieldChange:
      return {
        ...formPageFieldChange(state, action.payload)
      };
    case FormActionTypes.SetFormValidation:
      return formValidated(state, action.payload);
    case FormActionTypes.FocusField:
      return {
        ...state,
        focusField: action.payload
      };
    case FormActionTypes.CompletePage:
      return {
        ...completePage(state, action.payload)
      }
    case FormActionTypes.SummaryShown:
      return {
        ...state,
        summaryShown: action.payload
      }
    case FormActionTypes.SubmitClicked:
      return {
        ...state,
        submitClicked: action.payload
      }
    case FormActionTypes.FormSubmitted:
      return {
        ...state,
        formSubmitted: action.payload
      }
    case FormActionTypes.SetConfirmations:
      return {
        ...state,
        confirmations: action.payload
      }
    case FormActionTypes.SetSaveState:
      return {
        ...state,
        saveState: action.payload,
      }
    case FormActionTypes.SetFormId:
      return {
        ...state,
        formId: action.payload,
        formCreated: action.payload !== '0',
      }
    case FormActionTypes.SetError:
      const errorId = state.errors.length > 0 ? state.errors[state.errors.length - 1].id + 1 : 0;
      return {
        ...state,
        errors: [...state.errors, { id: errorId, errorMessage: action.payload }]
      }
    case FormActionTypes.RemoveError:
      return {
        ...state,
        errors: state.errors.filter((error) => error.id !== action.payload)
      }
      case FormActionTypes.FormStarted:
        return {
          ...state,
          formStarted: action.payload
        }
    default:
      return state;
  }
};

const formPageFieldChange = (state: FormState, payload: FormPageFieldChangePayload) => {
  const fieldIndex = state.form.pages[payload.pageIndex].fields.findIndex((f: any) => f.name === payload.field.name);
  if (fieldIndex < 0)
    return state;

  state.form.pages[payload.pageIndex].fields[fieldIndex].value = payload.value;
  state.lastEditedField = { name: payload.field.name, value: payload.value, pageIndex: payload.pageIndex };
  return state;
}

const formValidated = (state: FormState, payload: FormValidatedPayload) => {
  const newState = {...state};

  Object.keys(newState.validationByFieldName).forEach(fieldName => {
      if(payload.validation[fieldName] === undefined)
        delete newState.validationByFieldName[fieldName];
    });

  Object.keys(payload.validation).forEach(fieldName => {
    newState.validationByFieldName[fieldName] = payload.validation[fieldName];
  });

  switch (payload.validationType) {
    case FormValidationType.Form:
      newState.formValidated = true;
  }

  return newState;
}

const completePage = (state: FormState, payload: number) => {
  if (state.completedPages.indexOf(payload) === -1)
    state.completedPages.push(payload);

  return state;
}
