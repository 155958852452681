import React from 'react';
import { InputField } from '@in/component-library';

export const InputFieldWrapper = (props: any) => {

  const type = props.fieldType.replace('Field', '');
  
  return <InputField 
    { ...props }
    onBlur={props.onChange}
    onChange={() => {}}
    type={type}
  />
}
