import React, { FC } from 'react';
import MessageResponse from './message-response';
import { useParams } from 'react-router-dom';
import { postSeenByRecipient } from '../../apis/seen-by-recipient';
import { Message as MessageModel } from '../../models/statuspage/message';
import MessageThread from './message-thread';
import { MessagesType } from '../../models/statuspage/messages-type';
import MessageReply from '../message-reply/message-reply';
import { ApplicationCaseProcess } from '../../models/statuspage/application-case-process';
import { KeyedMutator } from 'swr';
import './messages.scss';

export interface CommunicationProps {
  applicationCaseProcess: ApplicationCaseProcess | undefined;
  formid: string | undefined;
  onPostMessage: KeyedMutator<ApplicationCaseProcess>;
}
const Communication: FC<CommunicationProps> = ({ applicationCaseProcess, formid, onPostMessage }) => {
  const { formId } = useParams<{ formId: string }>();

  async function handleSeenByRecipient(msg: MessageModel) {
    if (msg.seenByRecipient) return;
    return await postSeenByRecipient(formId, msg.id);
  }

  React.useEffect(() => {
    const firstUnseenMessage = applicationCaseProcess?.messages.find((msg) => msg.id === 0 && !msg.seenByRecipient);
    if (firstUnseenMessage) handleSeenByRecipient(firstUnseenMessage);
  }, [applicationCaseProcess?.messages]);

  return (
    <>
      <MessageResponse applicationCaseProcess={applicationCaseProcess} formid={formid} />
      {applicationCaseProcess &&
        applicationCaseProcess.messages.filter((msg) => msg.id !== 0 && msg.type !== MessagesType.Feedback).map((msg) => <MessageThread key={msg.id} message={msg} />)}

      {applicationCaseProcess &&
        applicationCaseProcess.messages
          .filter((msg) => msg.id === 0)
          .map((msg) => <MessageReply key={msg.id} message={msg} applicationCaseProcess={applicationCaseProcess} onPostMessage={onPostMessage} />)}
    </>
  );
};

export default Communication;
